.nc-MainNav1 {
  &.notOnTop {
    @apply bg-white dark:bg-neutral-900 backdrop-blur-2xl bg-opacity-70 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700;
  }
}

.menu-item.menu-megamenu:hover {
  > .sub-menu {
    @apply visible;
  }
}

.audio-playlist {
  width: 300px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 1000000;
  height: 700px;
  max-height: calc(100% - 10rem);
  overflow: hidden;

  .list-media {
    overflow: auto;
    height: calc(100% - 4rem);
  }
}

.nc-Card11 .nc-PostCardLikeAndComment {
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: 10;
  opacity: 0.8;
}

.nc-PostFeaturedMedia .m-button-listen, .nc-Card16Podcast .m-button-listen {
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  z-index: 10;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  -moz-transition: opacity 200ms ease-in-out;
  -ms-transition: opacity 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;
}

.nc-Card16Podcast .m-button-listen {
  left: calc(50% - 40px);
  top: calc(30% - 30px) !important;
}

.nc-PostFeaturedMedia:hover .m-button-listen, .nc-Card16Podcast:hover .m-button-listen {
  //opacity: 1;
  zoom: 1;
  filter: alpha(opacity=100);
  opacity: 1;
}

.heading-view-all {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
}
.heading-view-all .view-more{
  margin-top: 10px;
  font-size: 10pt;
}