.tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /*background: #e5e5e5;*/
  /*box-shadow: 0 48px 80px -32px rgba(0,0,0,0.3);*/
}
.tab-label {
  width: auto;
  padding: 15px 20px;
  /*background: #e5e5e5;*/
  cursor: pointer;
  font-weight: bold;
  /*font-size: 18px;*/
  /*color: #7f7f7f;*/
  transition: background 0.1s, color 0.1s;
}
/*.tab-label:hover {*/
/*  background: #d8d8d8;*/
/*}*/
/*.tab-label:active {*/
/*  background: #ccc;*/
/*}*/
.tab-label.active {
  /*background: #fff;*/
}